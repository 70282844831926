import toast from "react-hot-toast";
import { http } from "../lib";
import {
  ApiResponse,
  ICMS,
  IFaq,
  IProduct,
  IPurchases,
  IReport,
  ITicketStatus,
  ITicket,
  IUser,
  ITransaction,
} from "../types";

export class ApiService {
  async loadUser() {
    const response = await http.get<ApiResponse<{ user: IUser }>>(
      "/user/admin/me"
    );
    return response;
  }

  async login(email: string, password: string) {
    const response = await http.post<ApiResponse<{ token: string }>>(
      "/auth/admin/login",
      { email, password }
    );
    return response;
  }

  async getUsers(options?: {
    query?: string | null;
    status?: string | null;
    fromDate?: Date | null;
    toDate?: Date | null;
    offset?: number | null;
    limit?: number | null;
  }) {
    const response = await http.get<
      ApiResponse<{ users: IUser[]; total: number }>
    >("/user", {
      params: {
        ...(options?.query && { query: options.query }),
        ...(options?.status && { status: options.status }),
        ...(options?.fromDate && { fromDate: options.fromDate }),
        ...(options?.toDate && { toDate: options.toDate }),
        offset: options?.offset,
        limit: options?.limit,
      },
    });
    return response;
  }

  async getUserDetails(id: string) {
    const response = await http.get<ApiResponse<{ user: IUser }>>(
      `/user/${id}`
    );
    return response;
  }

  async getReports(
    fromDate?: string,
    toDate?: string,
    limit?: number,
    offset?: number,
    userId?: string
  ) {
    const response = await http.get<
      ApiResponse<{ reports: IReport[]; total: number }>
    >("/report", {
      params: {
        ...(fromDate && { fromDate }),
        ...(toDate && { toDate }),
        ...(limit && { limit }),
        ...(offset && { offset }),
        ...(userId && { userId }),
      },
    });
    return response;
  }

  async getProducts() {
    const response = await http.get<ApiResponse<{ products: IProduct[] }>>(
      "/product/admin"
    );
    return response;
  }

  async updateProductStatus(productId: string, active: boolean) {
    const response = await http.patch<ApiResponse<{}>>(
      `/product/${productId}/status`,
      { active }
    );
    return response;
  }

  async createProduct(product: any) {
    const response = await http.post<ApiResponse<{}>>("/product", product);
    return response;
  }

  async downloadInvoice(transactionId: string) {
    const response = await http.get<ApiResponse<{ url: string }>>(
      `/transaction/${transactionId}/download`
    );
    return response;
  }

  async updateUser({
    name,
    oldPassword,
    newPassword,
  }: {
    name?: string;
    oldPassword?: string;
    newPassword?: string;
  }) {
    const response = await http.patch<ApiResponse<{}>>("/user", {
      name,
      oldPassword,
      newPassword,
    });
    return response;
  }

  async updateUserStatus(userId: string, status: string) {
    const response = await http.patch<ApiResponse<{}>>(
      `/user/${userId}/status`,
      { status }
    );
    return response;
  }

  async updateProduct(productId: string, product: any) {
    const response = await http.put<ApiResponse<{}>>(
      `/product/${productId}`,
      product
    );
    return response;
  }

  async getCms() {
    const response = await http.get<ApiResponse<{ cms: ICMS }>>("/cms");
    return response;
  }

  async createFaq(faq: Omit<IFaq, "_id">) {
    const response = await http.post<ApiResponse<{}>>("/cms/faq", faq);
    return response;
  }

  async updateFaq(faqId: string, faq: Omit<IFaq, "_id">) {
    const response = await http.put<ApiResponse<{}>>(`/cms/faq/${faqId}`, faq);
    return response;
  }

  async updateCms(cms: any) {
    const response = await http.patch<ApiResponse<{}>>(`/cms`, cms);
    return response;
  }

  async getStats(type: string, fromDate?: Date, toDate?: Date) {
    const response = await http.get<ApiResponse<{ count: number }>>(
      "/admin/stats",
      { params: { type, fromDate, toDate } }
    );
    return response;
  }

  async getTickets({
    query,
    status,
    fromDate,
    toDate,
    limit,
    offset,
  }: {
    query?: string;
    status?: ITicketStatus;
    fromDate?: string;
    toDate?: string;
    limit?: number;
    offset?: number;
  }) {
    const response = await http.get<
      ApiResponse<{ tickets: ITicket[]; total: number }>
    >("/ticket/admin", {
      params: {
        ...(query && { query }),
        ...(status && { status }),
        ...(fromDate && { fromDate }),
        ...(toDate && { toDate }),
        ...(limit && { limit }),
        ...(offset && { offset }),
      },
    });
    return response;
  }

  async getTicket(ticketId: string) {
    const response = await http.get<ApiResponse<{ ticket: ITicket }>>(
      `/ticket/${ticketId}`
    );
    return response;
  }

  async createMessage(ticketId: string, message: string) {
    const response = await http.post<ApiResponse<{}>>(
      `/ticket/${ticketId}/message`,
      { message }
    );
    return response;
  }

  async updateTicketStatus(ticketId: string, status: ITicketStatus) {
    const response = await http.put<ApiResponse<{}>>(`/ticket/${ticketId}`);
    return response;
  }

  async getTransactions(
    fromDate?: string,
    toDate?: string,
    limit?: number,
    offset?: number,
    userId?: string
  ) {
    const response = await http.get<
      ApiResponse<{ transactions: ITransaction[]; total: number }>
    >("/transaction", {
      params: {
        ...(fromDate && { fromDate }),
        ...(toDate && { toDate }),
        ...(limit && { limit }),
        ...(offset && { offset }),
      },
    });
    return response;
  }
  async resetPasswordRequest(email: string) {
    const response = await http.post<ApiResponse<{}>>("/auth/admin/reset", {
      email,
    });
    return response;
  }

  async resetPassword(token: string, password: string) {
    const response = await http.post<ApiResponse<{}>>(
      `/auth/admin/reset/${token}`,
      {
        password,
      }
    );
    return response;
  }

  async deleteFaq(faqId: string) {
    const response = await http.delete<ApiResponse<{}>>(`/cms/faq/${faqId}`);
    return response;
  }
}

const apiService = new ApiService();

export default apiService;
