import React, { useEffect, useState } from "react";

import eye from "assets/images/auth/fi_eye.svg";
import RecreateLeft from "../common/auth/recreateLeftComponent";
import EmailComponent from "../common/auth/emailComponent";
import { Link } from "react-router-dom";
import { api } from "../../../api";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";

const ResetPasswordEmail = () => {
  const [email, setemail] = useState("");
  const [loading, setLoading] = useState(false);
  // const [password, setpassword] = useState('');

  const onInputEmail = (e) => setemail(e.target.value);
  // const onInputPassword = e => setpassword(e.target.value);
  return (
    <>
      <div className="w-full h-screen flex font-Outfit">
        {/* <div className="w-full "> */}
        {/* <RecreateLeft /> */}
        <div className="w-full flex flex-col items-center justify-center">
          <div className="w-1/4 pt-10">
            <div className="sign-in flex flex-col items-center gap-y-3">
              <p className="text-3xl font-black">Reset Password</p>
              <p className="border-amber-400 border-2 w-14 rounded-lg"></p>
              <p className="text-paragraph font-normal text-base">
                Please enter the details below to continue.
              </p>
            </div>
            <form className="py-5 ">
              <EmailComponent onInputEmail={onInputEmail} email={email} />
              <button
                className={` border-2 w-full py-3 flex justify-center border-border rounded-lg text-white font-semibold
                    ${
                      email
                        ? "opacity-1 bg-amber-400 border-amber-400"
                        : "bg-amber-400 opacity-[0.5] border-amber-400"
                    } `}
                // hover:opacity-100 hover:bg-button1
                disabled={!email}
                onClick={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  const resp = await api.resetPasswordRequest(email);
                  setLoading(false);
                  if (resp.data.error) {
                    toast.error(resp.data.error.message);
                    return;
                  }
                  toast.success(
                    resp.data.message ||
                      "Password reset link mailed to your email"
                  );
                }}
              >
                {loading ? (
                  <ThreeDots width={24} height={24} color="black" />
                ) : (
                  "Send Reset Password Link"
                )}
              </button>
            </form>
            <div></div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default ResetPasswordEmail;
