import React, { useState } from "react";
import eye from "assets/images/auth/fi_eye.svg";

const ConfirmComponent = ({ confirmpassword, onInputConfirmPassword }) => {
  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <>
      <div className="w-full pb-3 ">
        <label htmlFor="Password" className="pb-1 font-semibold text-base">
          Confirm Password
        </label>
        <br />
        <div className="relative">
          <input
            placeholder="Enter your password"
            type={passwordType}
            name="password"
            value={confirmpassword}
            onInput={onInputConfirmPassword}
            className="border-2 w-full px-4 py-3 border-border rounded-lg outline-0"
          ></input>
          <div
            onClick={togglePassword}
            className="absolute right-5 top-3 cursor-pointer"
          >
            <img src={eye} className="w-full" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmComponent;
