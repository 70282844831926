import axios from "axios";

// Create an Axios instance (optional, can also use axios directly)
// alert(import.meta.env.VITE_API)
const http = axios.create({
  // baseURL, headers, etc.
  baseURL: process.env.REACT_APP_API_URI,
  headers: {
    "Content-Type": "application/json", // Example of a common header
    Authorization: `Bearer ${
      localStorage.getItem("token") || sessionStorage.getItem("token")
    }`,
  },
});
http.interceptors.request.use((config) => {
  const token =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
// Add a response interceptor
http.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  (error) => {
    // Check if the response has a status of 401 or 403
    if (error.response && error.response.status === 401) {
      let authRoutes = [
        "/login",
        "/login/",
        "/register",
        "/register/",
        "/send-email/",
        "/send-email",
        "/reset-password",
        "/reset-password/",
      ];
      // Perform a hard reload
      if (!window.location.pathname.includes("/auth/reset")) {
        if (authRoutes.indexOf(window.location.pathname) === -1) {
          window.location.replace("/login");
        }
      }
    }
    // Return the error to allow further error handling
    return error?.response;
  }
);

export default http;
