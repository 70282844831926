import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
  useCallback,
} from "react";
import { IUser } from "../../types";
import { api } from "../../api";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { authRoutes } from "../../utils";
import { RotatingLines } from "react-loader-spinner";

// ... existing code ...

interface SessionContextType {
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  user: IUser | null; // Replace 'any' with a specific type if available
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>; // Replace 'any' with a specific type if available
  revalidate: () => void;
}

export const SessionContext = createContext<SessionContextType>({
  loading: true,
  setLoading: () => {},
  user: null,
  setUser: () => {},
  revalidate: () => {},
});

interface SessionProviderProps {
  children: ReactNode;
}

export const SessionProvider = ({ children }: SessionProviderProps) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IUser | null>(null); // Replace 'any' with a specific type if available

  const location = useLocation();
  const navigate = useNavigate();

  const loadUser = useCallback(
    async (user?: IUser | null) => {
      if (!user) {
        setLoading(true);
      }
      const resp = await api.loadUser();

      if (resp.data.error) {
        setUser(null);
        if (!location.pathname.includes("/auth/reset")) {
          if (!authRoutes.includes(location.pathname)) {
            navigate("/login", { replace: true });
          }
        }
      } else {
        toast.remove();
        setUser(resp.data.data.user);
        if (!location.pathname.includes("/auth/reset")) {
          if (authRoutes.includes(location.pathname)) {
            navigate("/", { replace: true });
          }
        }
      }
      setLoading(false);
    },
    [location.pathname]
  );

  const revalidate = useCallback(() => {
    loadUser(user);
  }, [loadUser, user]);

  useEffect(() => {
    loadUser();
  }, []);

  if (loading) {
    return (
      <div className="h-screen w-screen flex items-center justify-center">
        <RotatingLines
          visible={true}
          strokeColor="#FFD54F"
          width="96"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
      </div>
    );
  }
  return (
    <SessionContext.Provider
      value={{ loading, setLoading, user, setUser, revalidate }}
    >
      {children}
    </SessionContext.Provider>
  );
};

// ... existing code ...
