import React, { useCallback, useEffect, useState } from "react";

import RecreateLeft from "../common/auth/recreateLeftComponent";
import EmailComponent from "../common/auth/emailComponent";
import Passwordcomponent from "../common/auth/passwordComponent";
import eye from "assets/images/auth/fi_eye.svg";
import { useNavigate, Link } from "react-router-dom";
import { api } from "../../../api";
import toast from "react-hot-toast";
import { useSession } from "../../../hooks";
import { TailSpin, ThreeDots } from "react-loader-spinner";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false); // New state variable
  const [rememberMe, setRememberMe] = useState(false);
  const onInputEmail = (e) => {
    setEmail(e.target.value);
    setIsFormValid(e.target.value && password); // Update form validity
  };

  const onInputPassword = (e) => {
    setPassword(e.target.value);
    setIsFormValid(email && e.target.value); // Update form validity
  };
  const session = useSession();
  const [isLoading, setIsLoading] = useState(false);
  const handleLogin = useCallback(
    async (e: any) => {
      e.preventDefault();
      setIsLoading(true);
      const response = await api.login(email, password);

      if (response.data.error) {
        toast.error(response.data.error.message);
      } else {
        toast.success("Loggedin successfully!");
        if (!rememberMe) {
          sessionStorage.setItem("token", response.data.data.token);
        } else {
          localStorage.setItem("token", response.data.data.token);
        }
        session.revalidate();
      }
      setIsLoading(false);
    },
    [email, password, rememberMe]
  );
  // const [passwordType, setPasswordType] = useState('password');

  // const togglePassword = () => {
  //   if (passwordType === 'password') {
  //     setPasswordType('text');
  //     return;
  //   }
  //   setPasswordType('password');
  // };

  return (
    <>
      <div className="w-full h-screen flex font-Outfit ">
        {/* <div className="w-full "> */}
        {/* <RecreateLeft /> */}
        <div className="w-full  flex flex-col items-center justify-center">
          <div className="w-1/4 pt-10">
            <div className="sign-in flex flex-col items-center gap-y-3">
              <p className="text-3xl font-black">Sign In</p>
              <p className="border-amber-400 border-2 w-14 rounded-lg"></p>
              <p className="text-paragraph font-normal text-base">
                Please enter the details below to continue.
              </p>
            </div>
            <form className="py-5 ">
              <EmailComponent onInputEmail={onInputEmail} email={email} />
              {/* <div className="w-full pb-3">
                <label
                  htmlFor="Email"
                  className="pb-1 font-semibold text-base"
                >
                  Email
                </label>
                <br />
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={email}
                  onInput={onInputEmail}
                  className="border-2 w-full px-4 py-3 border-border rounded-lg outline-0"
                ></input>
              </div> */}
              <Passwordcomponent
                password={password}
                onInputPassword={onInputPassword}
              />
              {/* <div className="w-full pb-3 ">
                <label
                  htmlFor="Password"
                  className="pb-1 font-semibold text-base"
                >
                  Password
                </label>
                <br />
                <div className="relative">
                  <input
                    placeholder="Enter your password"
                    type={passwordType}
                    name="password"
                    value={password}
                    onInput={onInputPassword}
                    className="border-2 w-full px-4 py-3 border-border rounded-lg outline-0"
                  ></input>
                  <div
                    onClick={togglePassword}
                    className="absolute right-5 top-3 cursor-pointer"
                  >
                    <img src={eye} className="w-full" />
                  </div>
                </div>
              </div> */}
              <div className="flex justify-between pb-5">
                <div className="flex gap-x-2">
                  <input
                    type="checkbox"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                    className="w-5 h-5 border-2 border-border rounded-lg outline-0 "
                  />
                  <p className="">Remember me</p>
                </div>
                <Link to="/emailconfirmation">
                  <p className="">Forgot Password?</p>
                </Link>
              </div>
              <a
                //   href="https://2dph6qylk59.typeform.com/to/ObGGRwOt"
                target="_blank"
              >
                <button
                  disabled={!isFormValid || isLoading} // Disable button if form is not valid
                  onClick={handleLogin}
                  className={`bg-amber-400 flex justify-center border-2 w-full py-3 border-border rounded-lg text-white font-semibold ${
                    isFormValid
                      ? "opacity-1 bg-amber-400 border-amber-400"
                      : "bg-amber-400 opacity-[0.5] border-amber-400"
                  }`}
                >
                  {isLoading ? (
                    <ThreeDots width={24} height={24} color="black" />
                  ) : (
                    "Login"
                  )}
                </button>
              </a>
            </form>
            <div></div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default Login;
