import React, { useEffect, useState } from "react";

import RecreateLeft from "../common/auth/recreateLeftComponent";
import Passwordcomponent from "../common/auth/passwordComponent";
import eye from "assets/images/auth/fi_eye.svg";
import { useNavigate, Link, useParams } from "react-router-dom";
import EmailComponent from "../common/auth/emailComponent";
import ConfirmComponent from "../common/auth/confirmComponent";
import { ThreeDots } from "react-loader-spinner";
import { api } from "../../../api";
import toast from "react-hot-toast";

function ResetPassword() {
  const navigate = useNavigate();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [arePasswordsEqual, setArePasswordsEqual] = useState(false); // New state variable

  const onInputPassword = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    setArePasswordsEqual(inputValue === confirmPassword); // Update password equality
  };

  const onInputConfirmPassword = (e) => {
    const inputValue = e.target.value;
    setConfirmPassword(inputValue);
    setArePasswordsEqual(inputValue === password); // Update password equality
  };

  // const [passwordType, setPasswordType] = useState('password');

  // const togglePassword = () => {
  //   if (passwordType === 'password') {
  //     setPasswordType('text');
  //     return;
  //   }
  //   setPasswordType('password');
  // };

  const handleLogin = () => {
    // Perform login logic

    // After successful login, navigate to the home page
    navigate("/");
  };

  return (
    <>
      <div className="w-full h-screen flex font-Outfit ">
        {/* <div className="w-full "> */}
        {/* <RecreateLeft /> */}
        <div className="w-full  flex flex-col items-center justify-center">
          <div className="w-1/4 pt-10">
            <div className="sign-in flex flex-col items-center gap-y-3">
              <p className="text-3xl font-black">Reset Password</p>
              <p className="border-amber-400 border-2 w-14 rounded-lg"></p>
              <p className="text-paragraph font-normal text-base">
                Please enter the details below to continue.
              </p>
            </div>
            <form className="py-5 ">
              <Passwordcomponent
                password={password}
                onInputPassword={onInputPassword}
              />
              <ConfirmComponent
                confirmpassword={confirmPassword}
                onInputConfirmPassword={onInputConfirmPassword}
              />
              {/* <div className="w-full pb-3">
                <label
                  htmlFor="Email"
                  className="pb-1 font-semibold text-base"
                >
                  Email
                </label>
                <br />
                <input
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={email}
                  onInput={onInputEmail}
                  className="border-2 w-full px-4 py-3 border-border rounded-lg outline-0"
                ></input>
              </div> */}

              {/* <div className="w-full pb-3 ">
                <label
                  htmlFor="Password"
                  className="pb-1 font-semibold text-base"
                >
                  Password
                </label>
                <br />
                <div className="relative">
                  <input
                    placeholder="Enter your password"
                    type={passwordType}
                    name="password"
                    value={password}
                    onInput={onInputPassword}
                    className="border-2 w-full px-4 py-3 border-border rounded-lg outline-0"
                  ></input>
                  <div
                    onClick={togglePassword}
                    className="absolute right-5 top-3 cursor-pointer"
                  >
                    <img src={eye} className="w-full" />
                  </div>
                </div>
              </div> */}
              {/* <div className="flex justify-between pb-5">
                <div className="flex gap-x-2">
                  <input
                    type="checkbox"
                    className="w-5 h-5 border-2 border-border rounded-lg outline-0 "
                  />
                  <p className="">Remember me</p>
                </div>
                <Link to="/resetpassword">
                  <p className="">Forgot Password?</p>
                </Link>
              </div> */}

              <button
                disabled={!arePasswordsEqual}
                className={`mt-4  border-2 w-full py-3 flex justify-center border-border rounded-lg text-white font-semibold ${
                  arePasswordsEqual
                    ? "opacity-1 bg-amber-400 border-amber-400"
                    : "bg-amber-400 opacity-[0.5] border-amber-400"
                }`}
                onClick={async (e) => {
                  e.preventDefault();
                  setLoading(true);
                  const resp = await api.resetPassword(
                    params.token as string,
                    password
                  );
                  setLoading(false);
                  if (resp.data.error) {
                    return toast.error(resp.data.error.message);
                  }
                  toast.success(
                    resp.data.message || "Password reset successfully!"
                  );
                  await new Promise((resolve) => setTimeout(resolve, 800));
                  navigate("/login", { replace: true });
                }}
              >
                {loading ? (
                  <ThreeDots width={24} height={24} color="black" />
                ) : (
                  "Send Reset Password Link"
                )}
              </button>
            </form>
            <div></div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

export default ResetPassword;
