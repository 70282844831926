/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { GlobalStyle } from "styles/global-styles";

import { HomePage } from "./pages/HomePage/Loadable";
import { NotFoundPage } from "./pages/NotFoundPage/Loadable";
import { useTranslation } from "react-i18next";
import { LoginPage } from "./pages/Login";
import { EmailConfirmationPage } from "./pages/EmailConfirmation";
import ResetPassword from "./components/Resetpassword";
import { SettingsPage } from "./pages/SettingsPage";

export function App() {
  const { i18n } = useTranslation();
  return (
    <div>
      <Helmet
        titleTemplate="%s "
        defaultTitle="ffMapp."
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>

      <Routes>
        <Route path="/*" element={<HomePage />} />
        {/* <Route path="/settings/general*" element={<SettingsPage />} /> */}
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/login/*" element={<LoginPage />} />
        <Route
          path="/emailconfirmation/*"
          element={<EmailConfirmationPage />}
        />
        <Route path="/auth/reset/:token" element={<ResetPassword />} />
      </Routes>
      <GlobalStyle />
    </div>
  );
}
